import Router, { useRouter } from "next/router"
import Seo from "@common/seo"
import NProgress from "nprogress"
import * as gtag from "../lib/gtag"

import "../styles/global.css"
import Custom404 from "./404"
import ErrorBoundary from "components/errorBoundary"
import { THEME } from "configs/theme"
import { BeaconLoader } from "components/beaconLoader"
import { ThemeProvider } from "next-themes"
import { ToastProvider } from "@common/toast/toastProvider"

// GoogleAnalytics & nProgress setup
// to show progress only on fetch and on delay follow this guide - https://dev.to/vvo/show-a-top-progress-bar-on-fetch-and-router-events-in-next-js-4df3
NProgress.configure({ showSpinner: false })
Router.events.on("routeChangeStart", () => {
  NProgress.start()
})
Router.events.on("routeChangeComplete", (url) => {
  gtag.pageview(url)
  NProgress.done()
})
Router.events.on("routeChangeError", () => NProgress.done())

export default function App({ Component, pageProps }) {
  const router = useRouter()

  // SPA Layouting Solutions here - https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
  // Using Option 2 -
  // const home = router.pathname.indexOf('/d/') == -1 ? true : null
  // and choose layout based on above condition

  // Using Option 4
  const getLayout = Component.getLayout || ((page) => page)

  function getLayoutWithRouteCheck() {
    // Check for blacklisted paths -> If present show 404 page
    // Additionally, if a path contains "/document", also show 404 page in production environment

    const documentCheck =
      process.env.NODE_ENV === "production" &&
      router.pathname.includes("/components")

    if (
      THEME?.blackList?.some((path) =>
        new RegExp(`${path}`, "i").test(router.pathname)
      ) ||
      documentCheck
    ) {
      return <Custom404 {...pageProps} />
    } else {
      return getLayout(<Component {...pageProps} />)
    }
  }

  return (
    <>
      <ErrorBoundary>
        <ThemeProvider attribute="class" defaultTheme="light">
          <ToastProvider>
            <SiteHead />
            {getLayoutWithRouteCheck()}
          </ToastProvider>
          <BeaconLoader />
        </ThemeProvider>
      </ErrorBoundary>
    </>
  )
}

function SiteHead() {
  const router = useRouter()
  const path = router.asPath?.split("?")[0]
  const titleString = THEME?.seo?.title
  const description = THEME?.seo?.description
  const homeImageUrl = THEME?.seo?.homeImageUrl
  const siteName = THEME?.seo?.siteName
  const locale = THEME?.seo?.locale
  const canonical = `${THEME?.seo?.url}${path}`

  return (
    <Seo
      title={titleString}
      description={description}
      canonical={canonical}
      homeImageUrl={homeImageUrl}
      siteName={siteName}
      locale={locale}
      ogType="website"
      ogImagePath="image/png"
      ogImageWidth="1200"
      ogImageHeight="627"
      twitterCardContent="summary"
    />
  )
}
